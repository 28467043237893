<template>
    <div>
      <patent-annual-fee-reminder-list></patent-annual-fee-reminder-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  
  